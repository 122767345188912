import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"



import { ParallaxProvider } from 'react-scroll-parallax';
import Hero from "../components/hero"
import About from "../components/about";
import Details  from "../components/details";
import Contact from "../components/contact";
import Nutrients from "../components/nutrients";



const IndexPage = () => (

  <ParallaxProvider>

    <Layout>

      <SEO title="Aquaduo" />

      <Hero/>

      <About/>
      
      <Details/>

      <Nutrients/>

      <Contact/>
      

    </Layout>
  </ParallaxProvider>
)

export default IndexPage
