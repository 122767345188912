import React, { useState } from "react"

import Bubble from "../components/bubble"
import Waves from "../assets/svg/waves.svg";
import { StaticImage } from "gatsby-plugin-image"
import CtaButton from './ctaButton'
import { Parallax } from 'react-scroll-parallax';


const Hero = (props) => {


 
    return(
        <div id='home' className="container1 w-full h-[90%] md:h-[120%] relative z-[50]">

            <div className="heroContent animate__animated animate__fadeIn flex-col md:flex-row lg:px-[20%] md:px-[5%] px-[10%] py-[20%] md:py-[10%] items-center flex">

                <Parallax speed={-5} className={'h-full w-full md:w-[70%] flex items-center'}>
                    <div className="heroText lg:h-[80%] md:h-[50%] h-[80%]">
                        <h2>We <strong>purify</strong> your water to protect your health and your home</h2>
                        <CtaButton scrolled={false} text='LEARN MORE' href='/#about' title='About'/>
                    </div>
                </Parallax>
                <Parallax speed={-15}className={'h-[50%] w-[70%] items-center md:flex hidden'}>
                    <div className='heroImg  animate__animated animate__fadeInUp '>
                        <StaticImage
                            src="../images/filter1.png"
                            alt="Aquaduo filter"
                            placeholder="none"
                            layout="constrained"

                        />
                    </div>
                </Parallax>
                
                
            </div>





            <Bubble color={'#F9F9F9'} size='60px' border='15px' positionX='5%' positionY='40%' speed={10} blur={0.1} initX={0} finalX={-200}/>
            <Bubble color={'#F9F9F9'} size='40px' border='8px' positionX='10%' positionY='50%' speed={25} blur={0} initX={0} finalX={100}/>
            <Bubble color={'#F9F9F9'} size='40px' border='12px' positionX='80%' positionY='30%' speed={5} blur={0} initX={0} finalX={-100}/>
            <Bubble color={'#F9F9F9'} size='40px' border='10px' positionX='70%' positionY='50%' speed={30} blur={0} initX={0} finalX={200}/>

            <span className="absolute bottom-[-15px] w-full h-auto">
                    <Waves/>
                </span>   
            
        </div>
    )
}
export default Hero