import React, { useState } from "react"

import Bubble from "../components/bubble"
import Wave from "../assets/svg/wave.svg";
import Waves from "../assets/svg/waves.svg";
import { StaticImage } from "gatsby-plugin-image"
import { Parallax } from 'react-scroll-parallax';

import InvertColorsIcon from '@mui/icons-material/InvertColors';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import SpaIcon from '@mui/icons-material/Spa';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';

const iconStyle = {
    fill:'#00A3DA',
    width:'4rem',
    height:'4rem',

}
const iconStyle2 = {
    fill:'#212121',
    width:'4rem',
    height:'4rem',

}

const Hero = (props) => {


 
    return(
        <>
            <div id='about' className="bg-white w-full h-[auto] relative z-[60]">



                <div className="aboutItems  md:flex-row flex-col lg:px-[20%] px-[10%] mt-[20%]">
          
            
                    <div className="aboutItem md:w-[30%] w-[80%] md:items-start items-center mt-[20%] md:mt-0 md:justify-start justify-center text-center md:text-left">
                        <SpaIcon sx={iconStyle}/>
                        <p>The use of purified water provides great benefits for the skin and hair when used in body cleansing. Improves the washing of clothes or dishes by keep it in good condition.</p>
                    </div>
                    <div className="aboutItem md:w-[30%] w-[80%] md:items-start items-center mt-[20%] md:mt-0 md:justify-start justify-center text-center md:text-left">
                        <SoupKitchenIcon sx={iconStyle}/>
                        <p>Produces a better flavor in foods, such as soups and other side dishes, as well as beverages such as coffee or tea.</p>
                    </div>
                </div>
                <div className='aboutInfo  lg:px-[20%] px-[10%] px-[5%] md:flex-row flex-col'>
                    <Parallax speed={-7} style={{width:'80%'}}>
                        <div className='aboutImg'>
                                <StaticImage
                                    src="../images/filter1.png"
                                    alt="Aquaduo filter inside"
                                    placeholder="blurred"
                                    layout="constrained"

                                />
                        </div>
                    </Parallax>
                    <Parallax speed={10} className='h-full md:w-[60%] w-[90%] md:mt-0 mt-[30%]'>
                        <div className="aboutText">
                            <p>
                                Our purified water system is installed between the public water pipes and
                                the household intake to deliver a product free of harmful organic and
                                inorganic substances.  The supply covers your entire home, even for use
                                when bathing or filling the pool, it is only contraindicated to water plants.
                                 This purification process matches the highest standards in the market to
                                generate water with high purity for a better performance in domestic and
                                personal hygiene tasks by using less soap.
                            </p>
                        </div>
                    </Parallax>
                    
                </div>






                <span className="absolute bottom-[5px] translate-y-[100%] w-full h-auto">
                    <Wave/>
                </span>
                <Bubble  color={'#64C8EA'} size='40px' border='7px' positionX='15%' positionY='60%' speed={20} blur={0} initX={0} finalX={-50}/>
                <Bubble  color={'#64C8EA'} size='40px' border='12px' positionX='5%' positionY='35%' speed={40} blur={0.3} initX={0} finalX={200}/>
                <Bubble  color={'#64C8EA'} size='40px' border='10px' positionX='70%' positionY='10%' speed={15} blur={0} initX={0} finalX={-300}/>
                <Bubble  color={'#64C8EA'} size='40px' border='8px' positionX='55%' positionY='60%' speed={35} blur={0} initX={0} finalX={100}/>

            
        </div>
        <div  className="container2 w-full h-[auto] relative z-[50]">
            <div className="waterComponents md:px-[20%] px-[5%] text-center">
                <h2>THIS ARE THE COMPONENTS IN YOUR TAP WATER </h2>
                <div className="waterComponentsItems">
                    <span className=' basis-[50%] md:basis-[33%]'>
                        <BubbleChartIcon sx={iconStyle2}/>
                        <p>Pharmaceuticals</p>
                    </span>
                    <span className=' basis-[50%] md:basis-[33%]'>
                        <BubbleChartIcon sx={iconStyle2}/>
                        <p>Cyanide</p>
                    </span>
                    <span className=' basis-[50%] md:basis-[33%]'>
                        <BubbleChartIcon sx={iconStyle2}/>
                        <p>Chlorine</p>
                    </span>
                    <span className=' basis-[50%] md:basis-[33%]'>
                        <BubbleChartIcon sx={iconStyle2}/>
                        <p>Pollutants</p>
                    </span>
                    <span className=' basis-[50%] md:basis-[33%]'>
                        <BubbleChartIcon sx={iconStyle2}/>
                        <p>Arsenic</p>
                    </span>
                    <span className=' basis-[50%] md:basis-[33%]'>
                        <BubbleChartIcon sx={iconStyle2}/>
                        <p>Copper</p>
                    </span>
                </div>
                

            </div>
            <Bubble  color={'#ffffff'} size='40px' border='7px' positionX='15%' positionY='60%' speed={20} blur={0} initX={0} finalX={-50}/>
            <Bubble  color={'#ffffff'} size='40px' border='12px' positionX='5%' positionY='35%' speed={40} blur={0.3} initX={0} finalX={200}/>
            <Bubble  color={'#ffffff'} size='40px' border='10px' positionX='70%' positionY='10%' speed={15} blur={0} initX={0} finalX={-300}/>
            <Bubble  color={'#ffffff'} size='40px' border='8px' positionX='55%' positionY='60%' speed={35} blur={0} initX={0} finalX={100}/>

            
            <span className="absolute bottom-[-10px] w-full h-auto">
                <Waves/>
            </span>
        </div>
        </>
    )
}
export default Hero