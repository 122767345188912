import React, {useEffect, useRef, useState} from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import emailjs from '@emailjs/browser';
import Bubble from "./bubble";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const Contact = (props) => {
  const [formState,setFormState]=useState(null)
  const form = useRef();

  const [reason,setReason] = useState('Inquire')




  const sendEmail = (e) => {
    e.preventDefault();
    setFormState('sending')
    emailjs.sendForm('service_0pbj8o2', 'template_bbpugwr', form.current, 'SCN74o9XE669G8nmk')
      .then((result) => {
          setFormState('sent')
      }, (error) => {
          setFormState('sent')
          console.log(error.text);
      });
  };

  const inputStyle = {
    width:'100%',
    marginTop:'5%',
   
    [`& fieldset`]: {
        borderRadius: 2,

      },
  }

  
  return (
    <div id="contact" ref={props.ref} className="contactForm relative md:px-[10%] px-[5%] py-[20%]">

        <h3>Leave us a message!</h3>

        <form ref={form} onSubmit={sendEmail} className='form  w-[100%] lg:w-[60%] w-[80%]'>

        <RadioGroup
            row
            value={reason}
            onChange={(e)=>setReason(e.target.value)}
            name="user_reason"
        >
            <FormControlLabel value="Inquire" control={<Radio />} label="Inquire" />
            <FormControlLabel value="Maintenance" control={<Radio />} label="Maintenance" />

        </RadioGroup>


            <TextField 
                id="standard-basic" 
                sx={inputStyle}
                label="Email" 
                variant="outlined"
                InputProps={{ style: { fontSize: 20 } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                type='email'
                required
                name="user_email"
                autoComplete='off'
            />
            <TextField 
                id="standard-basic" 
                sx={inputStyle}
                label='Name'
                variant="outlined"
                InputProps={{ style: { fontSize: 20 } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                type='name'
                required
                name="user_name"
                autoComplete='off'
            />
            <TextField 
                id="standard-basic" 
                sx={inputStyle}
                label='Phone Number'
                variant="outlined"
                InputProps={{ style: { fontSize: 20 } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                type='number'
                name="user_phone"
                autoComplete='off'
            />
            <TextField 
                id="standard-basic" 
                sx={inputStyle}
                label='Address'
                variant="outlined"
                InputProps={{ style: { fontSize: 20 } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                type='string'
                name="user_address"
                autoComplete='off'
            />
            
          
            
            <TextField
                id="outlined-multiline-static"
                sx={inputStyle}
                label='Message'
                multiline
                variant="outlined"
                rows={4}
                InputProps={{ style: { fontSize: 20 } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="message"
                autoComplete='off'
            />
            <Button type='submit' variant="contained" sx={{
                backgroundColor: formState=='sent'?'#25c9d1':'#101010',
                marginTop:'5%',
                width:'50%',
                borderRadius:'20.5px',
                padding:'.5rem 2rem .5rem 2rem'
            }}>

                {formState=='sent' &&
                    'Thank you!'
                }
                {formState=='sending' &&
                    <CircularProgress />
                }
                {!formState &&
                    'Send'
                }
            
            </Button>

        </form>



        <Bubble  color={'#64C8EA'} size='40px' border='7px' positionX='15%' positionY='60%' speed={20} blur={0} initX={0} finalX={-50}/>
        <Bubble  color={'#64C8EA'} size='40px' border='12px' positionX='5%' positionY='35%' speed={40} blur={0.3} initX={0} finalX={200}/>
        <Bubble  color={'#64C8EA'} size='40px' border='10px' positionX='70%' positionY='10%' speed={15} blur={0} initX={0} finalX={-300}/>
        <Bubble  color={'#64C8EA'} size='40px' border='8px' positionX='55%' positionY='60%' speed={35} blur={0} initX={0} finalX={100}/>

    </div>
    
  )
}
export default Contact
