import React, { useState, ref } from "react"
import { Parallax } from 'react-scroll-parallax';


const Bubble = (props) => {

    const bubbleStyle = {
        width: props.size,
        height: props.size,
        backgroundColor: 'transparent',
        border: ` ${props.border} solid ${props.color} `,
        borderRadius:'50%',
        position: 'absolute',
        left: props.positionX,
        top: props.positionY,
        zIndex: '150',
        filter: `blur(${props.blur}px)`

    }

    return(
        <>
      
            <Parallax style={bubbleStyle} speed={props.speed} translateX={[props.initX,props.finalX]}  />
       
        </>
    )
}
export default Bubble